import type { AccountProfile, AccountProfileFilters } from "./types";
import { getParamStringFromObject } from "~/utils/helpers";
import { getSession } from "~/services/session";
import { fetchAPI } from "../fetchAPI";
import { fetchAPI2 } from "../fetchAPI2";
import { simulateApiError } from "../errors";

// TODO ! SWICH TO USE fetchAPI

export const _retrieveAccountProfile = async (
  filters?: AccountProfileFilters,
  options: { cleanShipTos: boolean } = { cleanShipTos: false }
): Promise<AccountProfile> => {
  const session = await getSession();
  // TODO - better solution to returning early?
  if (!session){
    if (import.meta.env.DEV) {
      console.log('[retrieveAccountProfile]: No session found;')
    }
    // @ts-expect-error
    return;
  }

  const params = filters ? getParamStringFromObject(filters) : "";

  // await simulateApiError({path: `/account/profile${params}`});

  const data = await fetchAPI2<AccountProfile>(`/account/profile${params}`);

  if (options.cleanShipTos && data.ShipTos) {
    data.ShipTos = data.ShipTos.filter((shipTo) => shipTo.Name !== "CUSTOM");
  }

  return data;
};
