import { action, json, redirect, reload, revalidate } from "@solidjs/router";
import { submitLogin } from "../roma-api/account";
import { getSession, getIsPartner, revalidateOnSession } from "../session";
import { listCarts } from "../cart/useListCarts";
import { APIError } from "../roma-api/errors";

export const loginAction = action(
  async (username: string, password: string) => {
    "use server";

    const response = await submitLogin(username, password);
    return json(response, { revalidate: revalidateOnSession });

    // ? OR handle the 3 login responses?

    // if ('Token' in resp && resp.Token !== "" ) {
    //   // setSession stuff..
    // } else if ('Transfer' in resp) {
    //   // redirect to a transfer form? or this should be handled in component, and add fields to form?
    // } else if ('Reset' in resp) {
    //   // redirect
    //   redirect(`/forgot-password?code=${resp.Code}&email=${resp.Email}`, {revalidate: "nothing??defaultIsToRevalideAllCachedFunctions"})
    // }
  }
);
