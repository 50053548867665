import { query, createAsync } from "@solidjs/router";
import { _retrieveAccountProfile } from "../roma-api/account";
import type { AccountProfileFilters } from "../roma-api/account/types";


export const getAccountProfile =  query(
  async (
    filters?: AccountProfileFilters,
    options?: { cleanShipTos: boolean }
  ) => _retrieveAccountProfile(filters, options),
  "account-profile"
);


// export const useAccountProfile = query(
//   async (
//     filters?: AccountProfileFilters,
//     options?: { cleanShipTos: boolean }
//   ) => _retrieveAccountProfile(filters, options),
//   "account-profile"
// );

// TODO - reactive 'use' version..