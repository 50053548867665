import type { AccountStatus } from "./types";
import { getSession } from "~/services/session";
import { fetchAPI2 } from "../fetchAPI2";
import { APIError, simulateApiError } from "../errors";

export const retrieveAccountStatus = async (): Promise<
  AccountStatus | undefined
> => {
  const session = await getSession();

  if (!session || !session?.token) {
    if (import.meta.env.DEV) {
      console.log("[retrieveAccountStatus]: No session/token found");
    }
    return;
  }

  // await simulateApiError({path: "/account/status"})

  const data = await fetchAPI2<AccountStatus>("/account/status");

  // throw new APIError({
  //   path: "/account/status",
  //   code: "INVALID_TOKEN_PARSE",
  //   statusCode: 401,
  //   message: "Could not properly validate incoming token."
  // })

  return data;
};
