import { query, createAsync } from "@solidjs/router";
import { retrieveAccountStatus } from "../roma-api/account";

export const getAccountStatus = query(
  () => retrieveAccountStatus(),
  "account-status"
);

export const useAccountStatus = (options = { deferStream: false }) =>
  createAsync(() => getAccountStatus(), options);
